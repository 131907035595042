import React from "react"

import Layout from "../components/layout"
import Nav from "../components/nav"
import Footer from "../components/footer"
import Slogan from "../components/slogan"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

import "../scss/attorno.scss"

const ContacteerOns = () => (
  <Layout>
    <SEO
      title="Contacteer ons"
      description="Had u toch nog vragen of wil u een offerte ontvangen? Contacteer ons nu vrijblijvend voor een voorstel."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{ backgroundImage: "url(/design/img/header/pizza.jpg)" }}
    >
      <div className="container">
        <Slogan heading="Neem contact met ons op" />
      </div>
    </header>

    <ContactForm phone="0031 (0) 639 28 10 34" email="info@attorno.nl" />

    <Footer />
  </Layout>
)

export default ContacteerOns
